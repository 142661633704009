// Load the base covid DATA
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React from "react";

const SWISS_CANTONS = require('./case_data/cantonsInfo.json');

export const GEO_REGIONS = SWISS_CANTONS.map(entry => entry.Abrev).sort();

export const COVID_COLORS_DICT = {
    'cases': '#4daf4a',
    'hosps': '#377eb8',
    'deaths': '#e41a1c',
    'tests': '#984ea3',
    'positifs': '#ff7f00'
}

export const COVID_LABELS_DICT = {
    'cases': 'Nb of cases',
    'hosps': 'Nb of hospitalized',
    'deaths': 'Nb of deceased',
    'tests': 'Total nb of tests',
    'positifs': 'Positive tests pct.'
}

export const RE_COLORS_DICT = {
    'line': '#08589e',
    'border': '#7bccc4',
    'area': '#e0f3db'
}


export const DICT_KEYS = Object.keys(COVID_COLORS_DICT);

let DATA_CACHE = {};
export const getData = function (dataType = 'cases', region = 'CH') {
    let key = dataType + '_' + region;
    if (!(key in DATA_CACHE)) {
        DATA_CACHE[key] = require(`./case_data/${key}.json`);
    }
    return DATA_CACHE[key];
}

let SUMMARY_CACHE = {};
export const getDataSummary = function (region = 'CH', nDays = '7') {
    let key = region;
    if (!(key in SUMMARY_CACHE)) {
        SUMMARY_CACHE[key] = require(`./case_data/Summary_${key}.json`);
    }
    return SUMMARY_CACHE[key][`NB_DAYS_${nDays}`];
}

export const renderDataSummary = function (currentRegion, data) {
    if (Object.keys(data).length === 0) {
        return (null);
    }

    let sumData7 = getDataSummary(currentRegion, 7);
    let sumData14 = getDataSummary(currentRegion, 14);

    const DATA_TYPE_TO_LOG = ['cases', 'hosps', 'deaths'];

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Number of days</TableCell>
                        <TableCell>Tested positive</TableCell>
                        <TableCell>Hospitalized</TableCell>
                        <TableCell>Deceased</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Last 7 days</TableCell>
                        {Object.keys(data).map(aKey =>
                            DATA_TYPE_TO_LOG.includes(aKey) ? <TableCell
                                key={["summary_7d", aKey].join("_")}> {sumData7[aKey].totalEntries} ({sumData7[aKey].totalEntriesPer100K} per
                                100k)</TableCell> : null
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>Last 14 days</TableCell>
                        {Object.keys(data).map(aKey =>
                            DATA_TYPE_TO_LOG.includes(aKey) ? <TableCell
                                key={["summary_14d", aKey].join("_")}> {sumData14[aKey].totalEntries} ({sumData14[aKey].totalEntriesPer100K} per
                                100k)</TableCell> : null
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}


const DEFAULT_RE_DATA = require('./re_data/CHE_CH.json');
export const RE_TIME_SPANS = ["All", "Last trimester", "Last month"];
export const RE_DATA_TYPE = Array.from(new Set(DEFAULT_RE_DATA.map(entry => entry.data_type))).sort();
export const RE_ESTIMATE_TYPE = Array.from(new Set(DEFAULT_RE_DATA.map(entry => entry.estimate_type))).sort();

let RE_CACHE = {};
export const getReData = function (
    dataType = 'Deaths',
    estimatorType = 'Cori_slidingWindow',
    region = 'CH',
    timeSpan = RE_TIME_SPANS[0]) {
    let key = 'CHE_' + region;
    if (!(key in RE_CACHE)) {
        RE_CACHE[key] = require(`./re_data/${key}.json`);
    }
    let filteredData = RE_CACHE[key].filter(entry => entry.data_type === dataType && entry.estimate_type === estimatorType);
    let data = filteredData.map(entry => {
        entry.range = [entry.median_R_lowHPD, entry.median_R_highHPD];
        entry.logVal = Math.log(entry.median_R_mean);
        entry.logRange = [Math.log(entry.median_R_lowHPD), Math.log(entry.median_R_highHPD)];
        return entry;
    });
    if (timeSpan === RE_TIME_SPANS[1]) {
        return data.slice(-90);
    } else if (timeSpan === RE_TIME_SPANS[2]) {
        return data.slice(-30);
    }
    return data;
}


const percentColors = [
    {pct: 0.0, color: {r: 0xee, g: 0xee, b: 0xee}},
    {pct: 1.0, color: {r: 0x22, g: 0x22, b: 0x22}}];

// From https://stackoverflow.com/questions/7128675/from-green-to-red-color-depend-on-percentage/17267684
export const getColorForPercentage = function (pct) {
    let i;
    for (i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    let lower = percentColors[i - 1];
    let upper = percentColors[i];
    let range = upper.pct - lower.pct;
    let rangePct = (pct - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;
    let color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
};

