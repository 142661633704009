import React from 'react';

import {myTheme} from "./Theming";

import BaseCovidChart from './BaseCovidChart.js';
import ReCovidChart from "./ReCovidChart";
import ResponsiveMapCH from "./BaseCovidMap.js";

import PropTypes from 'prop-types';

import { ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                {children}
                </React.Fragment>
                )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function getTabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Title() {
  return (
        <Box mt={3} alignItems="center" justifyContent="center">
            <Typography variant="h4" component="h1" align="center" gutterBottom >
                Covid CH
            </Typography>
        </Box>
  );
}

function App() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ThemeProvider theme={myTheme}>
            <Title />
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="COVID visualizers" centered>
                    <Tab label="Charts" {...getTabProps(0)} />
                    <Tab label="Taux Re" {...getTabProps(1)} />
                    <Tab label="Swiss map" {...getTabProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <BaseCovidChart />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ReCovidChart />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ResponsiveMapCH />
            </TabPanel>
        </ThemeProvider>
    );
}

export default App;
