import React, {Component} from 'react';
import Box from '@material-ui/core/Box';

import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ReferenceArea
} from 'recharts';
import {
    getData,
    DICT_KEYS,
    COVID_COLORS_DICT,
    COVID_LABELS_DICT,
    GEO_REGIONS,
    renderDataSummary
} from './DataManipulator.js';

import {myTheme, myStyles} from "./Theming";
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AxisLabel from "./AxisLabel";

class BaseCovidChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentRegion: 'CH',
            metricType: 'absolute',
            geoRegions: GEO_REGIONS,
            data: {},
            quote: "",
            refAreaLeft: "",
            refAreaRight: "",
            left: "dataMin",
            right: "dataMax",
        }
        this.handleChange = this.handleChange.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    handleChange(event) {
        let curRegion = this.state.currentRegion;
        if (event.target.name === 'GeoRegionSelector') {
            this.setState({currentRegion: event.target.value});
            curRegion = event.target.value;

            let data = {};
            for (let key of DICT_KEYS) {
                data[key] = getData(key, curRegion);
            }
            this.setState({data: data});

        } else if (event.target.name === 'MetricTypeOption') {
            this.setState({metricType: event.target.value});
        } else {
            throw Error("Not good");
        }

    }

    componentDidMount() {
        let data = {};
        for (let key of DICT_KEYS) {
            data[key] = getData(key, this.state.currentRegion);
        }
        this.setState({data: data});
    }

    renderSummary() {
        return (
            <Box className={this.props.classes.BCC_Summary} component={Paper} elevation={3}>
                {renderDataSummary(this.state.currentRegion, this.state.data)}
            </Box>
        );
    }

    renderInput() {
        return (
            <Box className={this.props.classes.BCC_Input} component={Paper} elevation={3}>
                <form>
                    <TableContainer className="InputTable">
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Browse regions: </TableCell>
                                    <TableCell>
                                        <select name="GeoRegionSelector" value={this.state.currentRegion}
                                                onChange={this.handleChange}>
                                            {this.state.geoRegions.map(region =>
                                                <option key={region} value={region}>{region}</option>)}
                                        </select>
                                    </TableCell>
                                    <TableCell>
                                        <label>
                                            <span key="form_metric_label">Measure type: </span>
                                            <span key="form_metric_input_abs"><input type="radio" value="absolute"
                                                                                     name="MetricTypeOption"
                                                                                     checked={this.state.metricType === 'absolute'}
                                                                                     onChange={this.handleChange}/> Absolute </span>
                                            <span key="form_metric_input_per100k"><input type="radio" value="per100k"
                                                                                         name="MetricTypeOption"
                                                                                         checked={this.state.metricType === 'per100k'}
                                                                                         onChange={this.handleChange}/> Per 100k </span>
                                        </label>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </form>
            </Box>
        );
    }

    onMouseDown(event) {
        this.setState({refAreaLeft: event.activeLabel});
    }

    onMouseMove(event) {
        if (this.state.refAreaLeft !== "") {
            this.setState({refAreaRight: event.activeLabel});
        }
    }

    onMouseUp(event) {
        this.zoom();
    }

    zoom() {
        let refAreaLeft = this.state.refAreaLeft;
        let refAreaRight = this.state.refAreaRight;
        if (refAreaLeft === refAreaRight || refAreaRight === "") {
            this.setState(() => ({
                refAreaLeft: "",
                refAreaRight: ""
            }));
            return;
        }

        // xAxis domain
        if (refAreaLeft > refAreaRight)
            [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        this.setState(() => ({
            refAreaLeft: "",
            refAreaRight: "",
            left: refAreaLeft,
            right: refAreaRight,
        }));
    }

    zoomOut() {
        this.setState(() => ({
            refAreaLeft: "",
            refAreaRight: "",
            left: "dataMin",
            right: "dataMax",
        }));
    }

    renderCharts() {

        return (
            <Box className={this.props.classes.BCC_Charts} component={Paper} elevation={3}>
                {Object.keys(this.state.data).map(aKey =>
                    <ResponsiveContainer key={['RespCtr-', aKey].join()} width="100%" height={200}>
                        <ComposedChart key={['CompChart-', aKey].join()}
                                       cx="50%"
                                       cy="50%"
                                       outerRadius="100%"
                                       data={this.state.data[aKey]}
                                       /*onMouseDown={this.onMouseDown}
                                       onMouseMove={this.onMouseMove}
                                       onMouseUp={this.onMouseUp}*/
                        >
                            <XAxis dataKey="datum" domain={[new Date(this.state.left), new Date(this.state.right)]} />
                            <YAxis label={<AxisLabel axisType='yAxis'>{COVID_LABELS_DICT[aKey]}</AxisLabel>}/>
                            <Line isAnimationActive={false}
                                  dataKey={this.state.metricType === "absolute" ? "yFitted" : "yFittedPer100K"}
                                  dot={false} strokeWidth={4} stroke={COVID_COLORS_DICT[aKey]}/>
                            <Bar isAnimationActive={false}
                                 dataKey={this.state.metricType === "absolute" ? "y" : "yPer100K"}
                                 fill={COVID_COLORS_DICT[aKey]}/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip/>
                            {this.state.refAreaLeft && this.state.refAreaRight ? (
                                <ReferenceArea
                                    x1={this.state.refAreaLeft}
                                    x2={this.state.refAreaRight}
                                    strokeOpacity={0.3}
                                />
                            ) : null}
                        </ComposedChart>
                    </ResponsiveContainer>
                )}
            </Box>
        );
    }

    render() {
        return (
            <div key="BaseCovidChart" className="BaseCovidChart">
                {this.renderInput()}
                {this.renderSummary()}
                {this.renderCharts()}
            </div>
        );
    }
}

export default withStyles(myStyles(myTheme))(BaseCovidChart);
