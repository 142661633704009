import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {myStyles, myTheme} from "./Theming";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import {Typography} from "@material-ui/core";

import {getData, renderDataSummary, DICT_KEYS, getDataSummary, getColorForPercentage} from './DataManipulator.js';

const MAP_CH = require(`./maps/map.json`);

const Tooltip = () => <div>tooltip</div>

class ResponsiveMapCH extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cantons: [],
            currentRegion: 'CH',
            data: {}
        }

        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
    }

    mouseOver(event) {
        this.setState({currentRegion: event.target.id})
    }

    mouseOut(event) {
        this.setState({currentRegion: "CH"})
    }

    componentDidMount() {
        let data = {};
        for(let key of DICT_KEYS) {
            data[key] = getData(key, this.state.currentRegion);
        }
        this.setState({ data : data });

        let total = 0.;
        let cantons = [];
        for(let canton of MAP_CH) {
            let sumData = getDataSummary(canton.name, 7);
            canton['stats'] = sumData['cases']['totalEntriesPer100K'];
            cantons.push(canton);
            total += canton['stats'];
        }

        let sortedCantons = cantons.sort(function(a, b){return a['stats']-b['stats']});
        let min = sortedCantons[0]['stats'];
        let max = sortedCantons[sortedCantons.length-1]['stats'];

        cantons = [];
        for(let canton of sortedCantons) {
            let pct = (canton.stats-min)/(max-min) ;
            canton['color'] = getColorForPercentage(pct);
            cantons.push(canton);
        }
        this.setState({cantons: cantons})

    }

    renderSummary() {
        return (
            <Box className={this.props.classes.BCC_Summary} component={Paper} elevation={3}>
                <Typography variant="h6" component="h4" align="center" gutterBottom >
                    Region: {this.state.currentRegion}
                </Typography>
                {renderDataSummary(this.state.currentRegion, this.state.data)}
            </Box>
        );
    }

    generateMapCH() {
        return (
            <React.Fragment>
                {this.renderSummary()}
                <svg
                     viewBox="0 0 1000 643"
                     xmlns='http://www.w3.org/2000/svg'
                     fill='#7c7c7c'
                     stroke='#fff'
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     strokeWidth='2'
                     version='1.2'
                >
                    {this.state.cantons.map((canton) =>
                        <path
                            id={canton.name}
                            key={'canton_' + canton.name}
                            fill={(canton.color || '#7c7c7c' )}
                            d={canton.path}
                            onMouseOver={this.mouseOver}
                            onMouseOut={this.mouseOut}
                        >
                        </path>)
                    }

                    <circle id='0' cx='202.8' cy='392.3'></circle>
                    <circle id='1' cx='760' cy='112.8'></circle>
                    <circle id='2' cx='859.2' cy='344.4'></circle>
                </svg>
            </React.Fragment>
        );
    }


    render() {
        return (
        <Box className={this.props.classes.BCC_Maps} component={Paper} elevation={3}>
            {this.generateMapCH()}
        </Box>
        );
    }
};

export default withStyles(myStyles(myTheme))(ResponsiveMapCH);