import { createMuiTheme } from '@material-ui/core/styles';

export const myTheme = createMuiTheme( {
    typography: {
        fontSize: 20,
    },
    spacing: 4,
    overrides: {
        MuiTableRow: {
            root: {
                "&:last-child td": {
                    borderBottom: 0,
                },
            }
        }
    }
});

export const myStyles = theme => ({
    BCC_Input: {
        width: "80%",
        margin: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    BCC_Summary: {
        width: "80%",
        margin: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    BCC_Charts: {
        width: "80%",
        margin: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    BCC_Maps: {
        width: "80%",
        margin: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
});