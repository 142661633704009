import React, { Component } from 'react';
import Box from '@material-ui/core/Box';

import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Area, ReferenceLine
} from 'recharts';
import { getReData, RE_TIME_SPANS, RE_COLORS_DICT, RE_DATA_TYPE, RE_ESTIMATE_TYPE, GEO_REGIONS } from './DataManipulator.js';

import{myTheme, myStyles} from "./Theming";
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AxisLabel from "./AxisLabel"

class ReCovidChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentRegion: 'CH',
            dataType: 'Deaths',
            estimatorType: 'Cori_slidingWindow',
            timeSpan: 'All',
            geoRegions: GEO_REGIONS,
            data: getReData(),
            quote: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let curRegion = this.state.currentRegion;
        let curDataType = this.state.dataType;
        let curEstimatorType = this.state.estimatorType;
        let curTimeSpan = this.state.timeSpan;
        if(event.target.name === 'GeoRegionSelector') {
            this.setState({currentRegion: event.target.value});
            curRegion = event.target.value;
        } else if(event.target.name === 'DataTypeSelector') {
            this.setState({dataType: event.target.value});
            curDataType = event.target.value;
        } else if(event.target.name === 'EstimatorTypeSelector') {
            this.setState({estimatorType: event.target.value});
            curEstimatorType = event.target.value;
        } else if(event.target.name === 'TimeSpanSelector') {
            this.setState({timeSpan: event.target.value});
            curTimeSpan = event.target.value;
        } else {
            throw Error("Not good");
        }

        let data = getReData(curDataType, curEstimatorType, curRegion, curTimeSpan);
        this.setState({ data : data });
    }

    componentDidMount() {
        let data = getReData();
        this.setState({ data : data });
    }

    renderInput() {
        return(
            <Box className={this.props.classes.BCC_Input} component={Paper} elevation={3}>
                <form>
                    <TableContainer className="InputTable">
                        <Table size="small" >
                            <TableBody>
                                <TableRow>
                                    <TableCell>Browse regions: </TableCell>
                                    <TableCell>
                                        <select name="GeoRegionSelector" value={this.state.currentRegion} onChange={this.handleChange}>
                                            {this.state.geoRegions.map(region =>
                                                <option key={region} value={region}>{region}</option>)}
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Data type for Re estimation: </TableCell>
                                    <TableCell>
                                        <select name="DataTypeSelector" value={this.state.dataType} onChange={this.handleChange}>
                                            {RE_DATA_TYPE.map(dataType =>
                                                <option key={dataType} value={dataType}>{dataType}</option>)}
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Type of estimator (e.g., smoothing): </TableCell>
                                    <TableCell>
                                        <select name="EstimatorTypeSelector" value={this.state.estimatorType} onChange={this.handleChange}>
                                            {RE_ESTIMATE_TYPE.map(estimateType =>
                                                <option key={estimateType} value={estimateType}>{estimateType}</option>)}
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Time span: </TableCell>
                                    <TableCell>
                                        <select name="TimeSpanSelector" value={this.state.timeSpan} onChange={this.handleChange}>
                                            {RE_TIME_SPANS.map(timeSpan =>
                                                <option key={timeSpan} value={timeSpan}>{timeSpan}</option>)}
                                        </select>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </form>
            </Box>
        );
    }

    renderCharts(){
        return(
            <Box className={this.props.classes.BCC_Charts} component={Paper} elevation={3}>
                <ResponsiveContainer key='ReChartCtr' width="100%" height={400}>
                    <ComposedChart key='ReComposedChart'
                                   cx="50%"
                                   cy="50%"
                                   outerRadius="100%"
                                   data={this.state.data}
                    >
                        <XAxis dataKey="date" />
                        <YAxis label={<AxisLabel axisType='yAxis'>Re</AxisLabel>} />
                        <ReferenceLine y={1} label="Re=1" strokeWidth={4} stroke="red" strokeDasharray="3 3" />
                        <Area dataKey="range" stroke={RE_COLORS_DICT['border']} fill={RE_COLORS_DICT['area']} />
                        <Line dataKey='median_R_mean' dot={false} strokeWidth={4} stroke={RE_COLORS_DICT['line']} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                    </ComposedChart>
                </ResponsiveContainer>
                <ResponsiveContainer key='LogReChartCtr' width="100%" height={400}>
                    <ComposedChart key='LogReComposedChart'
                                   cx="50%"
                                   cy="50%"
                                   outerRadius="100%"
                                   data={this.state.data}
                    >
                        <XAxis dataKey="date" />
                        <YAxis label={<AxisLabel axisType='yAxis'>Log Re</AxisLabel>} />
                        <ReferenceLine y={0} label="Log Re=0" strokeWidth={4} stroke="red" strokeDasharray="3 3" />
                        <Line dataKey='logVal' dot={false} strokeWidth={4} stroke={RE_COLORS_DICT['line']} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
        );
    }

    render() {
        return (
            <div key="ReCovidChart" className="ReCovidChart">
                {this.renderInput()}
                {this.renderCharts()}
            </div>
        );
    }
}

export default withStyles(myStyles(myTheme))(ReCovidChart);
